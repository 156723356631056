import React from 'react';
import { useMsal } from '@azure/msal-react';
import Button from '@material-ui/core/Button';

const LogoutButtonComponent = () => {
  const { instance } = useMsal();

  return (
    <Button color='primary' onClick={() => instance.logout()}>
      Logout
    </Button>
  );
};

export default LogoutButtonComponent;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CustSelect from "../fields/CustSelect";
import RegField from "../fields/RegField";

const useStyles = makeStyles((theme) => ({
  surroundText: {
    display: "flex",
    alignItems: "center",
    marginTop: "1em",
    marginBottom: "0.5em",
  },
  formControl: {
    width: "60%",
    marginTop: "1em",
    marginBottom: "0.5em",
  },
}));

const CallType = (props) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { setShowTypeWarning } = props;

  const handleChange = (e) => {
    e.preventDefault();
    if (values.claims.callType === "Other") {
      if (values.claims.reason !== "") {
        setShowTypeWarning({ show: true, option: e.target.value });
      } else {
        setFieldValue("claims.callType", e.target.value);
      }
    } else if (e.target.value === "Other") {
      if (
        values.claims.claimNumber !== "" ||
        values.claims.callerType !== "" ||
        values.claims.division !== "" ||
        values.claims.reason !== "" ||
        values.claims.attempts !== "" ||
        values.claims.adjuster !== "" ||
        values.claims.examiner !== "" ||
        values.claims.supervisor !== "" ||
        values.claims.transferred.adjuster === true ||
        values.claims.transferred.examiner === true ||
        values.claims.transferred.supervisor === true
      ) {
        setShowTypeWarning({ show: true, option: e.target.value });
      } else {
        setFieldValue("claims.callType", e.target.value);
      }
    } else {
      setFieldValue("claims.callType", e.target.value);
    }
  };

  const badCallOptions = [
    "Ghost Call",
    'Misdirects – Customer Care',
    "Misdirect - Other AMIG Department",
    "Misdirect - Wrong Company",
  ];

  return (
    <Fragment>
      <RadioGroup
        name="claims.callType"
        value={values.claims.callType}
        onChange={handleChange}
        row
      >
        <FormControlLabel
          value="New Claim"
          control={<Radio color="primary" />}
          label="New Claim"
        />
        <FormControlLabel
          value="Follow Up"
          control={<Radio color="primary" />}
          label="Follow Up"
        />
        <FormControlLabel
          value="Other"
          control={<Radio color="primary" />}
          label="Other"
        />
      </RadioGroup>
      {values.claims.callType === "Other" && (
        <FormControl className={classes.formControl}>
          <InputLabel id="badcall-select">Other Call Type</InputLabel>
          <CustSelect
            options={badCallOptions}
            labelid="badcall-select"
            name="claims.reason"
            value="claims.reason"
            type="select"
          />
        </FormControl>
      )}
      {(values.claims.callType === "New Claim" ||
        values.claims.callType === "Follow Up") && (
        <div className={classes.surroundText}>
          <RegField
            name="claims.claimNumber"
            value="claims.claimNumber"
            type="text"
            label="Claim Number"
          />
        </div>
      )}
    </Fragment>
  );
};

CallType.propTypes = {
  setShowTypeWarning: PropTypes.func.isRequired,
};

export default CallType;

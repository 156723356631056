import React, { Fragment, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from './Header';
import Master from './Master';
// import UserContext from "../../context/user/userContext";
import {
  MsalAuthenticationTemplate,
  useMsal,
} from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { callMsGraph } from '../../utils/MsGraphApiCall';
import { loginRequest } from '../../auth/authConfig';
import { ErrorComponent } from '../ui/ErrorComponent';
import { Loading } from '../ui/Loading';

const useStyles = makeStyles((theme) => ({
  loading: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Home = () => {
  const classes = useStyles();
  //const userContext = useContext(UserContext);
  //const [ready, setReady] = useState(false);
  const [department, setDepartment] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  const { inProgress } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const authRequest = {
    ...loginRequest,
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      callMsGraph().then((response) => {
        setGraphData(response)
      });
    }
  }, [inProgress]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}>
      <Header />
      {graphData ? (
        <Master
          department={department}
          setDepartment={setDepartment}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ) : null}
    </MsalAuthenticationTemplate>
  );
};

export default Home;

import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import CustSelect from "../fields/CustSelect";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "70%",
    marginTop: "0.75em",
    marginBottom: "0.5em",
  },
}));

const Reason = () => {
  const classes = useStyles();

  const reasonOptions = [
    "Adjuster’s Contact Information",
    "Cat Inquiry",
    "Check Question - Adjuster Required",
    "Check Question - CSR Resolved",
    "Document Inquiry - Email Received",
    "Document Inquiry - Fax Received",
    "Document Inquiry - Mail Received",
    "FNOL",
    "General Inquiry - No Existing Claim",
    "General Inquiry or Status - Adjuster Required",
    "General Inquiry or Status - CSR Resolved",
    "IA Status",
    "No Contact to Date",
    "Old Claim Inquiry",
  ];

  const attemptOptions = [
    "Customer Called Multiple Times",
    "Customer Indicated Upset",
    "Customer Left Multiple Messages",
  ];

  return (
    <Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="reason-select">Reason</InputLabel>
        <CustSelect
          options={reasonOptions}
          labelid="reason-select"
          name="claims.reason"
          value="claims.reason"
          type="select"
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="attempts-select">NI Contact Attempts</InputLabel>
        <CustSelect
          options={attemptOptions}
          labelid="attempts-select"
          name="claims.attempts"
          value="claims.attempts"
          type="select"
        />
      </FormControl>
    </Fragment>
  );
};

export default Reason;

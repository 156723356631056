import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  doubleField: {
    width: "48%",
  },
}));

const DoubleField = ({ label, ...props }) => {
  const classes = useStyles();
  const [field] = useField(props);
  return <TextField {...field} className={classes.doubleField} label={label} />;
};

export default DoubleField;

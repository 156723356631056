import React, { Fragment } from "react";
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CustSelect from "../fields/CustSelect";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "70%",
    marginTop: "0.75em",
    marginBottom: "0.5em",
  },
}));

const TransferOutcome = () => {
  const classes = useStyles();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = (e) => {
    if (e.target.name === "claims.transferred.adjuster") {
      if (
        values.claims.transferred.adjuster === true &&
        e.target.checked === false
      ) {
        setFieldValue("claims.outcome.adjuster", "");
        setFieldTouched("claims.outcome.adjuster", false);
      }
    } else if (e.target.name === "claims.transferred.examiner") {
      if (
        values.claims.transferred.examiner === true &&
        e.target.checked === false
      ) {
        setFieldValue("claims.outcome.examiner", "");
        setFieldTouched("claims.outcome.examiner", false);
      }
    } else if (e.target.name === "claims.transferred.supervisor") {
      if (
        values.claims.transferred.supervisor === true &&
        e.target.checked === false
      ) {
        setFieldValue("claims.outcome.supervisor", "");
        setFieldTouched("claims.outcome.supervisor", false);
      }
    }
    setFieldValue(e.target.name, e.target.checked);
  };

  const transferOptions = [
    "Accepted Call",
    "Refused Call",
    "IC Answered",
    "IC No Answer",
    "Rang No Answer",
    "Straight to VM",
  ];

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.claims.transferred.adjuster}
            onChange={handleChange}
            name="claims.transferred.adjuster"
            color="primary"
          />
        }
        label="Adjuster"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values.claims.transferred.examiner}
            onChange={handleChange}
            name="claims.transferred.examiner"
            color="primary"
          />
        }
        label="Examiner"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values.claims.transferred.supervisor}
            onChange={handleChange}
            name="claims.transferred.supervisor"
            color="primary"
          />
        }
        label="Supervisor"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values.claims.transferred.cssLead}
            onChange={handleChange}
            name="claims.transferred.cssLead"
            color="primary"
          />
        }
        label="CSS Lead"
      />
      {values.claims.transferred.adjuster === true && (
        <FormControl className={classes.formControl}>
          <InputLabel id="transfer-select">Adjuster Transfer</InputLabel>
          <CustSelect
            options={transferOptions}
            labelid="transfer-select"
            name="claims.outcome.adjuster"
            value="claims.outcome.adjuster"
            type="select"
          />
        </FormControl>
      )}
      {values.claims.transferred.examiner === true && (
        <FormControl className={classes.formControl}>
          <InputLabel id="transfer-select">Examiner Transfer</InputLabel>
          <CustSelect
            options={transferOptions}
            labelid="transfer-select"
            name="claims.outcome.examiner"
            value="claims.outcome.examiner"
            type="select"
          />
        </FormControl>
      )}
      {values.claims.transferred.supervisor === true && (
        <FormControl className={classes.formControl}>
          <InputLabel id="transfer-select">Supervisor Transfer</InputLabel>
          <CustSelect
            options={transferOptions}
            labelid="transfer-select"
            name="claims.outcome.supervisor"
            value="claims.outcome.supervisor"
            type="select"
          />
        </FormControl>
      )}
      {values.claims.transferred.cssLead === true && (
        <FormControl className={classes.formControl}>
          <InputLabel id="transfer-select">CSS Lead Transfer</InputLabel>
          <CustSelect
            options={transferOptions}
            labelid="transfer-select"
            name="claims.outcome.cssLead"
            value="claims.outcome.cssLead"
            type="select"
          />
        </FormControl>
      )}
    </Fragment>
  );
};

export default TransferOutcome;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import Warning from "./Warning";

const ResetWarning = (props) => {
  const { resetForm } = useFormikContext();
  const { showResetWarning, setShowResetWarning, handleReset } = props;

  const handleClose = (selected) => {
    if (selected) {
      resetForm({});
      handleReset();
    }
    setShowResetWarning({ show: false, option: "" });
  };

  return (
    <Fragment>
      <Warning
        showWarning={showResetWarning}
        handleClose={handleClose}
        title="Clear the form?"
        message="This action cannot be undone."
      />
    </Fragment>
  );
};

ResetWarning.propTypes = {
  showResetWarning: PropTypes.object.isRequired,
  setShowResetWarning: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};

export default ResetWarning;

import React from 'react';
import Button from '@material-ui/core/Button';
import { useMsal } from '@azure/msal-react';

const LoginButtonComponent = ({ loginType = 'popup' }) => {
  const { instance } = useMsal();
  
  const handleLogin = () => {
    if (loginType === 'popup') {
      instance.loginPopup();
    } else if (loginType === 'redirect') {
      instance.loginRedirect();
    }
  };

  return (
    <Button color='primary' onClick={() => handleLogin()}>
      Login
    </Button>
  );
};

export default LoginButtonComponent;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import Warning from "./Warning";

const DepWarning = (props) => {
  const { resetForm } = useFormikContext();
  const {
    setDepartment,
    showDepWarning,
    setShowDepWarning,
    handleReset,
  } = props;

  const handleClose = (selected) => {
    if (selected) {
      resetForm({});
      handleReset();
      setDepartment(showDepWarning.option);
    }
    setShowDepWarning({ show: false, option: "" });
  };

  return (
    <Fragment>
      <Warning
        showWarning={showDepWarning}
        handleClose={handleClose}
        title="Change department selection?"
        message="This will erase any data entered on the current form."
      />
    </Fragment>
  );
};

DepWarning.propTypes = {
  setDepartment: PropTypes.func.isRequired,
  showDepWarning: PropTypes.object.isRequired,
  setShowDepWarning: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};

export default DepWarning;

import React from "react";
import { useField } from "formik";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const CustAutofill = (props) => {
  const [field, meta, helpers] = useField(props);
  const { label } = props;
  return (
    
    <Autocomplete
      {...field}
      {...props}
      onChange={(event, newValue) => {
        helpers.setValue(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} margin='normal' />
      )}
    />
  );
};

export default CustAutofill;

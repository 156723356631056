import React, { Fragment } from "react";

import CustRadio from "../fields/CustRadio";

const AmSuiteConversion = () => {
  return (
    <Fragment>
      <CustRadio
        name="cs.amSuiteConversion"
        type="radio"
        value="Yes"
        label="Yes"
      />
      <CustRadio
        name="cs.amSuiteConversion"
        type="radio"
        value="No"
        label="No"
      />
    </Fragment>
  );
};

export default AmSuiteConversion;

import React, { Fragment } from "react";

import CustRadio from "../fields/CustRadio";

const CsCallerType = () => {
  return (
    <Fragment>
      <CustRadio
        name="cs.callerType"
        type="radio"
        value="Agent"
        label="Agent"
      />
      <CustRadio
        name="cs.callerType"
        type="radio"
        value="Insured"
        label="Insured"
      />
      <CustRadio
        name="cs.callerType"
        type="radio"
        value="Lienholder"
        label="Lienholder"
      />
      <CustRadio
        name="cs.callerType"
        type="radio"
        value="Other"
        label="Other"
      />
    </Fragment>
  );
};

export default CsCallerType;

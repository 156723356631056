import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import Warning from "./Warning";

const IdWarning = (props) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { showIdWarning, setShowIdWarning, formOfId, setFormOfId } = props;

  const handleClose = (selected) => {
    if (selected) {
      if (formOfId === "policy") {
        setFieldValue("cs.policyId", "");
        setFieldTouched("cs.policyId", false);
      } else if (formOfId === "quote") {
        setFieldValue("cs.quoteNumber", "");
        setFieldTouched("cs.quoteNumber", false);
      } else if (formOfId === "agent") {
        setFieldValue("cs.agent", "");
        setFieldTouched("cs.agent", false);
        setFieldValue("cs.producer", "");
        setFieldTouched("cs.producer", false);
      }
      setFieldValue("cs.system", "");
      setFieldTouched("cs.system", false);
      setFormOfId("");
    }
    setShowIdWarning({ show: false, option: "" });
  };

  return (
    <Fragment>
      <Warning
        showWarning={showIdWarning}
        handleClose={handleClose}
        title="Return to identification type selection?"
        message="This will erase any data entered on the current form of
        identification."
      />
    </Fragment>
  );
};

IdWarning.propTypes = {
  showIdWarning: PropTypes.object.isRequired,
  setShowIdWarning: PropTypes.func.isRequired,
  formOfId: PropTypes.string.isRequired,
  setFormOfId: PropTypes.func.isRequired,
};

export default IdWarning;

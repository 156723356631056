import React, { Fragment } from "react";

import CustRadio from "../fields/CustRadio";

const Division = () => {
  return (
    <Fragment>
      <CustRadio
        name="claims.division"
        type="radio"
        value="Casualty"
        label="Casualty"
      />
      <CustRadio
        name="claims.division"
        type="radio"
        value="Field"
        label="Field"
      />
      <CustRadio
        name="claims.division"
        type="radio"
        value="Property"
        label="Property"
      />
      <CustRadio
        name="claims.division"
        type="radio"
        value="RecProduct"
        label="RecProduct"
      />
    </Fragment>
  );
};

export default Division;

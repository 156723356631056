import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Snack = (props) => {
  const { showSnack, setShowSnack } = props;

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnack((prevState) => {
      return { ...prevState, show: false };
    });
  };

  return (
    <Snackbar
      open={showSnack.show}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={showSnack.type}>
        {showSnack.type === "success" && "Submission successful!"}
        {showSnack.type === "error" && "Submission failed!"}
      </Alert>
    </Snackbar>
  );
};

Snack.propTypes = {
  showSnack: PropTypes.object.isRequired,
  setShowSnack: PropTypes.func.isRequired,
};

export default Snack;

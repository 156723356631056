import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

//import CustRadio from "../fields/CustRadio";
import CustSelect from "../fields/CustSelect";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "70%",
    marginTop: "0.75em",
    marginBottom: "0.5em",
  },
}));

const callerTypeOptions = [
  "Agent",
  "Attorney",
  "Claimant",
  "Contractor",
  "Insured",
  "Lienholder",
  "Public Adjuster",
  "Relative",
  "Other",
];

const ClaimsCallerType = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="caller-type-select">Select a Caller Type</InputLabel>
        <CustSelect
          options={callerTypeOptions}
          labelid="caller-type-select"
          name="claims.callerType"
          value="claims.callerType"
          type="select"
        />
      </FormControl>
      {/* <CustRadio
        name="claims.callerType"
        type="radio"
        value="Agent"
        label="Agent"
      />
      <CustRadio
        name="claims.callerType"
        type="radio"
        value="Insured"
        label="Insured"
      />
      <CustRadio
        name="claims.callerType"
        type="radio"
        value="Claimant"
        label="Claimant"
      />
      <CustRadio
        name="claims.callerType"
        type="radio"
        value="Contractor"
        label="Contractor"
      />
      <CustRadio
        name="claims.callerType"
        type="radio"
        value="Relative"
        label="Relative"
      />
      <CustRadio
        name="claims.callerType"
        type="radio"
        value="Public Adjuster"
        label="Public Adjuster"
      />
      <CustRadio
        name="claims.callerType"
        type="radio"
        value="Attorney"
        label="Attorney"
      />
      <CustRadio
        name="claims.callerType"
        type="radio"
        value="Other"
        label="Other"
      /> */}
    </Fragment>
  );
};

export default ClaimsCallerType;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import IconButton from "@material-ui/core/IconButton";

import CustRadio from "../fields/CustRadio";
import RegField from "../fields/RegField";
import DoubleField from "../fields/DoubleField";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "1em",
    marginBottom: "1em",
  },
  buttonOption: {
    marginRight: "1.5em",
  },
  surroundText: {
    display: "flex",
    alignItems: "center",
    marginTop: "0.5em",
    marginBottom: "1em",
  },
  surroundTextDouble: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.5em",
    marginBottom: "1em",
  },
}));

const CallerIdentification = (props) => {
  const { values, setFieldTouched } = useFormikContext();

  const classes = useStyles();

  const { formOfId, setFormOfId, setShowIdWarning } = props;

  const onStepBack = (values) => {
    if (formOfId === "policy") {
      if (values.cs.policyId !== "" || values.cs.system !== "") {
        setShowIdWarning({ show: true, option: "" });
      } else {
        setFieldTouched("cs.policyId", false);
        setFormOfId("");
      }
    } else if (formOfId === "quote") {
      if (values.cs.quoteNumber !== "" || values.cs.system !== "") {
        setShowIdWarning({ show: true, option: "" });
      } else {
        setFieldTouched("cs.quoteNumber", false);
        setFormOfId("");
      }
    } else if (formOfId === "agent") {
      if (
        values.cs.agent !== "" ||
        values.cs.producer !== "" ||
        values.cs.system !== ""
      ) {
        setShowIdWarning({ show: true, option: "" });
      } else {
        setFieldTouched("cs.agent", false);
        setFieldTouched("cs.producer", false);
        setFormOfId("");
      }
    }
  };

  return (
    <Fragment>
      {formOfId === "" && (
        <div className={classes.buttonGroup}>
          <Button
            onClick={() => {
              setFormOfId("policy");
            }}
            variant="contained"
            className={classes.buttonOption}
          >
            Policy ID
          </Button>
          <Button
            onClick={() => {
              setFormOfId("quote");
            }}
            variant="contained"
            className={classes.buttonOption}
          >
            Quote Number
          </Button>
          <Button
            onClick={() => {
              setFormOfId("agent");
            }}
            variant="contained"
            className={classes.buttonOption}
          >
            Agent Information
          </Button>
        </div>
      )}

      {formOfId !== "" && (
        <IconButton onClick={() => onStepBack(values)}>
          <NavigateBeforeIcon></NavigateBeforeIcon>
        </IconButton>
      )}

      {formOfId === "policy" && (
        <div className={classes.surroundText}>
          <RegField
            name="cs.policyId"
            value="cs.policyId"
            type="text"
            label="Policy ID"
          />
        </div>
      )}

      {formOfId === "quote" && (
        <div className={classes.surroundText}>
          <RegField
            name="cs.quoteNumber"
            value="cs.quoteNumber"
            type="text"
            label="Quote Number"
          />
        </div>
      )}

      {formOfId === "agent" && (
        <div className={classes.surroundTextDouble}>
          <DoubleField
            name="cs.agent"
            value="cs.agent"
            type="text"
            label="Agent"
          />
          <DoubleField
            name="cs.producer"
            value="cs.producer"
            type="text"
            label="Sub-Producer"
          />
        </div>
      )}

      {formOfId !== "" && (
        <Fragment>
          <CustRadio
            name="cs.system"
            type="radio"
            value="AmSuite Core"
            label="AmSuite Core"
          />
          <CustRadio
            name="cs.system"
            type="radio"
            value="AmSuite Portal"
            label="AmSuite Portal"
          />
          <CustRadio
            name="cs.system"
            type="radio"
            value="Legacy (M/L)"
            label="Legacy (M/L)"
          />
          <CustRadio name="cs.system" type="radio" value="MLP3" label="MLP3" />
        </Fragment>
      )}
    </Fragment>
  );
};

CallerIdentification.propTypes = {
  formOfId: PropTypes.string.isRequired,
  setFormOfId: PropTypes.func.isRequired,
  setShowIdWarning: PropTypes.func.isRequired,
};

export default CallerIdentification;

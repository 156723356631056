import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "0.5em 1em 0.5em 1em",
  },
}));

const Warning = (props) => {
  const classes = useStyles();
  const { showWarning, handleClose, title, message } = props;

  return (
    <Fragment>
      <Dialog
        open={showWarning.show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        maxWidth="xs"
        fullWidth
      >
        <div className={classes.dialog}>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} autoFocus>
              Cancel
            </Button>
            <Button
              onClick={() => handleClose(true)}
              color="primary"
              variant="contained"
              style={{ color: "white" }}
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Fragment>
  );
};

Warning.propTypes = {
  showWarning: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Warning;

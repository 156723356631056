import React from 'react';
import { ThemeProvider } from '@material-ui/styles';

import theme from './components/ui/Theme';
import Home from './components/ui/Home';
import './App.css';
import UserState from './context/user/UserState';
import Header from './components/ui/Header';
import { MsalProvider } from '@azure/msal-react';
import { createStore } from 'redux';

// const store = createStore(
//   auth,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

const App = ({ pca }) => {
  return (
    <MsalProvider instance={pca}>
      <ThemeProvider theme={theme}>
        <Header />
        <Home />
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;

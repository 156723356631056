import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import appSettings from '../../appSettings';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import StepButton from '@material-ui/core/StepButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';

import Department from '../Department';
import ClaimsCallerType from '../claims-steps/ClaimsCallerType';
import CallType from '../claims-steps/CallType';
import Division from '../claims-steps/Division';
import Contacts from '../claims-steps/Contacts';
import Reason from '../claims-steps/Reason';
import TransferOutcome from '../claims-steps/TransferOutcome';

import CsCallerType from '../cs-steps/CsCallerType';
import CallerIdentification from '../cs-steps/CallerIdentification';
import AmSuiteConversion from '../cs-steps/AmSuiteConversion';
import OptIn from '../cs-steps/OptIn';
import Transfer from '../cs-steps/Transfer';
import CallReason from '../cs-steps/CallReason';

import CommentsField from '../fields/CommentsField';

import Snack from '../popups/Snack';
import IdWarning from '../popups/IdWarning';
import DepWarning from '../popups/DepWarning';
import TypeWarning from '../popups/TypeWarning';
import ResetWarning from '../popups/ResetWarning';

const useStyles = makeStyles((theme) => ({
  buttonBack: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  buttonNext: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: 'white',
  },
  iconText: {
    fill: 'white',
  },
  paper: {
    padding: theme.spacing(2),
  },
  submitFab: {
    position: 'fixed',
    zIndex: 1,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    paddingLeft: '2em',
    paddingRight: '2em',
  },
  stepLabels: {
    paddingTop: '1em',
    paddingBottom: '1em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  dynamicLabel: {
    color: theme.palette.common.blue,
    display: 'inline',
  },
  resetButtonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1em',
    marginRight: '1em',
  },
  resetFab: {
    paddingLeft: '1.5em !important',
    paddingRight: '1.5em !important',
  },
}));

const Master = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const classes = useStyles();
  const [formOfId, setFormOfId] = useState('');
  const [showSnack, setShowSnack] = useState({
    show: false,
    type: '',
  });
  const [showIdWarning, setShowIdWarning] = useState({
    show: false,
    option: '',
  });
  const [showDepWarning, setShowDepWarning] = useState({
    show: false,
    option: '',
  });
  const [showTypeWarning, setShowTypeWarning] = useState({
    show: false,
    option: '',
  });
  const [showResetWarning, setShowResetWarning] = useState({
    show: false,
    option: '',
  });
  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    if (inProgress === 'none' && accounts.length > 0) {
      // Retrieve an access token
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: loginRequest.scopes,
        })
        .then((response) => {
          setAccessToken(response.accessToken);
        });
    }
  }, [inProgress, accounts, instance]);

  const { department, setDepartment, activeStep, setActiveStep } = props;

  const getDynamicLabel = (step, values) => {
    if (step === 0) {
      return department;
    }
    if (department === 'Customer Service') {
      switch (step) {
        case 1:
          return values.cs.callerType;
        case 2:
          if (
            formOfId === 'policy' &&
            values.cs.policyId !== '' &&
            values.cs.system !== ''
          ) {
            return `Policy ID: ${values.cs.policyId} / ${values.cs.system}`;
          } else if (
            formOfId === 'quote' &&
            values.cs.quoteNumber !== '' &&
            values.cs.system !== ''
          ) {
            return `Quote Number: ${values.cs.quoteNumber} / ${values.cs.system}`;
          } else if (
            formOfId === 'agent' &&
            values.cs.agent !== '' &&
            values.cs.producer !== '' &&
            values.cs.system !== ''
          ) {
            return `Agent: ${values.cs.agent} / Sub-Producer: ${values.cs.producer} / ${values.cs.system}`;
          } else {
            return '';
          }
        case 3:
          return values.cs.amSuiteConversion;
        case 4:
          return values.cs.optIn;
        case 5:
          return values.cs.transferred;
        case 6:
          return values.cs.reason.join(', ');
        default:
          return '';
      }
    } else if (department === 'Claims') {
      switch (step) {
        case 1:
          if (
            (values.claims.callType === 'New Claim' ||
              values.claims.callType === 'Follow Up') &&
            values.claims.claimNumber !== ''
          ) {
            return `${values.claims.callType} / Claim Number: ${values.claims.claimNumber}`;
          } else if (
            values.claims.callType === 'Other' &&
            values.claims.reason !== ''
          ) {
            return `${values.claims.callType} / ${values.claims.reason}`;
          } else {
            return '';
          }
        case 2:
          return values.claims.callerType;
        case 3:
          return values.claims.division;
        case 4:
          if (values.claims.reason !== '' && values.claims.attempts !== '') {
            return `${values.claims.reason} / ${values.claims.attempts}`;
          } else {
            return '';
          }
        case 5:
          if (
            values.claims.adjuster !== '' ||
            values.claims.examiner !== '' ||
            values.claims.supervisor !== ''
          ) {
            return `${
              values.claims.adjuster !== ''
                ? `Adjuster: ${values.claims.adjuster}`
                : 'No Adjuster'
            } / ${
              values.claims.examiner !== ''
                ? `Examiner: ${values.claims.examiner}`
                : 'No Examiner'
            } / ${
              values.claims.supervisor !== ''
                ? `Supervisor: ${values.claims.supervisor}`
                : 'No Supervisor'
            } / ${
              values.claims.cssLead !== ''
                ? `CSS Lead: ${values.claims.cssLead}`
                : 'No CSS Lead'
            }`;
          } else {
            return '';
          }
        case 6:
          if (
            values.claims.outcome.adjuster !== '' ||
            values.claims.outcome.examiner !== '' ||
            values.claims.outcome.supervisor !== ''
          ) {
            return `${
              values.claims.outcome.adjuster !== ''
                ? `Adjuster: ${values.claims.outcome.adjuster}`
                : 'No Adjuster Transfer'
            } / ${
              values.claims.outcome.examiner !== ''
                ? `Examiner: ${values.claims.outcome.examiner}`
                : 'No Examiner Transfer'
            } / ${
              values.claims.outcome.supervisor !== ''
                ? `Supervisor: ${values.claims.outcome.supervisor}`
                : 'No Supervisor Transfer'
            }`;
          } else {
            return '';
          }
        default:
          return '';
      }
    }
  };

  const getSteps = (values) => {
    if (department === 'Customer Service') {
      return [
        'Department',
        'Caller Type',
        'Caller Identification',
        'Related to AMSuite Conversion?',
        'Offered Opt In and Captured Cell Phone Number?',
        'Transferred?',
        'Call Reason',
      ];
    } else if (department === 'Claims') {
      if (values.claims.callType === 'Other' || values.claims.callType === '') {
        return ['Department', 'Call Type & Claim Number'];
      } else if (
        values.claims.callType === 'New Claim' ||
        values.claims.callType === 'Follow Up'
      ) {
        return [
          'Department',
          'Call Type & Claim Number',
          'Caller Type',
          'Division',
          'Reason & Attempts',
          'Contacts',
          'Transfer',
        ];
      }
    } else if (department === '') {
      return ['Department'];
    }
  };

  const getStepContent = (step) => {
    if (step === 0) {
      return (
        <Department
          department={department}
          setDepartment={setDepartment}
          setShowDepWarning={setShowDepWarning}
          setActiveStep={setActiveStep}
        />
      );
    }
    if (department === 'Customer Service') {
      switch (step) {
        case 1:
          return <CsCallerType />;
        case 2:
          return (
            <CallerIdentification
              formOfId={formOfId}
              setFormOfId={setFormOfId}
              setShowIdWarning={setShowIdWarning}
            />
          );
        case 3:
          return <AmSuiteConversion />;
        case 4:
          return <OptIn />;
        case 5:
          return <Transfer />;
        case 6:
          return <CallReason />;
        default:
          return 'Unknown step';
      }
    } else if (department === 'Claims') {
      switch (step) {
        case 1:
          return <CallType setShowTypeWarning={setShowTypeWarning} />;
        case 2:
          return <ClaimsCallerType />;
        case 3:
          return <Division />;
        case 4:
          return <Reason />;
        case 5:
          return <Contacts />;
        case 6:
          return <TransferOutcome />;
        default:
          return 'Unknown step';
      }
    }
  };

  const totalSteps = (values) => {
    return getSteps(values).length;
  };

  const isLastStep = (values) => {
    return activeStep === totalSteps(values) - 1;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(1);
    setFormOfId('');
  };

  const onSubmit = async (data, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    //  make async call
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${appSettings.CALL_TRACKER_API_KEY}`,
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const res = await axios.post(
        `${appSettings.CALL_TRACKER_API_BASE_URL}/cases`,
        { ...data, enteredBy: accounts[0].name },
        config
      );
      if (res.status === 200) {
        setShowSnack({ show: true, type: 'success' });
      } else {
        setShowSnack({ show: true, type: 'error' });
      }
    } catch (error) {
      console.log('Error: ', error);
    }

    resetForm({});
    handleReset();
    setSubmitting(false);
  };

  // const submitDisabled = (isSubmitting, dirty, isValid, values) => {
  //   if (values.claims.callType === "Other" && values.claims.reason !== "") {
  //     return false;
  //   } else if (isSubmitting || !dirty || !isValid || formOfId === "") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const handleClear = () => {
    setShowResetWarning({ show: true, option: '' });
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          claims: {
            callType: '',
            claimNumber: '',
            callerType: '',
            division: '',
            reason: '',
            attempts: '',
            adjuster: '',
            examiner: '',
            supervisor: '',
            cssLead: '',
            transferred: {
              adjuster: false,
              examiner: false,
              supervisor: false,
              cssLead: false,
            },
            outcome: {
              adjuster: '',
              examiner: '',
              supervisor: '',
              cssLead: '',
            },
          },
          cs: {
            callerType: '',
            policyId: '',
            quoteNumber: '',
            agent: '',
            producer: '',
            system: '',
            amSuiteConversion: '',
            optIn: '',
            transferred: '',
            reason: [],
          },
          comments: '',
        }}
        // validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting, dirty, values }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Paper elevation={3} className={classes.paper}>
                  <Stepper
                    nonLinear
                    activeStep={activeStep}
                    orientation='vertical'>
                    {getSteps(values).map((label, index) => (
                      <Step key={label}>
                        <StepButton
                          className={classes.stepLabels}
                          onClick={handleStep(index)}>
                          <StepLabel
                            StepIconProps={{
                              classes: {
                                text: classes.iconText,
                              },
                            }}>
                            {label}
                            <span style={{ paddingLeft: '1em' }} />
                            <p className={classes.dynamicLabel}>
                              {getDynamicLabel(index, values)}
                            </p>
                          </StepLabel>
                        </StepButton>
                        <StepContent>
                          <div>
                            {getStepContent(index, values)}
                            <div>
                              <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                className={classes.buttonBack}>
                                Back
                              </Button>
                              {index !== 6 && (
                                <Button
                                  disabled={isLastStep(values)}
                                  variant='contained'
                                  color='primary'
                                  onClick={handleNext}
                                  className={classes.buttonNext}>
                                  Next
                                </Button>
                              )}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Paper>
                {dirty && (
                  <div style={{ width: '100%' }}>
                    <Box display='flex' p={1} alignItems='baseline'>
                      <Box p={1} flexGrow={1}>
                        <Fab
                          type='submit'
                          disabled={isSubmitting || !dirty}
                          variant='extended'
                          color='secondary'>
                          Submit
                          <SendIcon style={{ marginLeft: '0.5em' }} />
                        </Fab>
                      </Box>
                      <Box p={1}>
                        <Fab
                          onClick={handleClear}
                          variant='extended'
                          size='small'
                          className={classes.resetFab}>
                          Clear Form
                        </Fab>
                      </Box>
                    </Box>
                  </div>
                )}
              </Grid>
              <Grid item xs={5}>
                <Paper elevation={3} className={classes.paper}>
                  <CommentsField
                    name='comments'
                    type='text'
                    value='comments'
                    label='Comments'
                  />
                </Paper>

                <Snack showSnack={showSnack} setShowSnack={setShowSnack} />
                <IdWarning
                  showIdWarning={showIdWarning}
                  setShowIdWarning={setShowIdWarning}
                  formOfId={formOfId}
                  setFormOfId={setFormOfId}
                />
                <DepWarning
                  setDepartment={setDepartment}
                  showDepWarning={showDepWarning}
                  setShowDepWarning={setShowDepWarning}
                  handleReset={handleReset}
                />
                <TypeWarning
                  showTypeWarning={showTypeWarning}
                  setShowTypeWarning={setShowTypeWarning}
                />
                <ResetWarning
                  showResetWarning={showResetWarning}
                  setShowResetWarning={setShowResetWarning}
                  handleReset={handleReset}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

Master.propTypes = {
  department: PropTypes.string.isRequired,
  setDepartment: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

export default Master;

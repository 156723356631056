import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import CSReasonsField from "../fields/CSReasonsField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "90%",
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
}));

const CallReason = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="reason-select">Reason</InputLabel>
        <CSReasonsField
          labelid="reason-select"
          name="cs.reason"
          value="cs.reason"
          type="select"
        />
      </FormControl>
    </Fragment>
  );
};

export default CallReason;

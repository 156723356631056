import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import CustAutofill from '../fields/CustAutofill';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '60%',
    marginTop: '0.75em',
    marginBottom: '0.5em',
  }
}));

const adjusterNames = [
  'Jeff Morgan',
  'Melissa Kellerman',
  'Jennifer Anderson',
  'Catey Berry',
  'Robin Bressler',
  'Thelma Kiser',
  'Dave McGuire',
  'Vickey Fletcher',
  'Steve Bova',
  'Molly Turner Fogelman',
  'Heather Ivers',
  'Stacey Staton',
  'Rhonda McDowell-Miller',
  'Jeff Allen',
  'Sherry Harris',
  'Stephane Hembree',
  'John Horgan',
  'Stephanie Heaton',
  'Marc Lytle',
  'Mark Bryant',
  'Crystal Davidson',
  'Lynn Davis',
  'Laura Kaplan',
  'Jody Blankenship',
  'Buffy Clements',
  'Dave Gifford',
  'Todd Warner',
  'Scott Wedemeyer',
  'Scott Moore',
  'Kathy Crowley',
  'Ben Ingland',
  'Dena Kirkham',
  'Lizz Lindgren-Royce',
  'Patty Smith',
  'Jim Grimm',
  'Jackie Adamson',
  'Sue Binegar',
  'Janice Cook',
  'Rowdy DeTellem',
  'Kyrstal De Vito',
  'Lavenda Evans',
  'Miekal Harrison',
  'Sharyl Kittel',
  'Matt Koopman',
  'Tammie Luck',
  'Jim Luton',
  'Virgil Marcum',
  'Marc McQuitty',
  'Nic Miltiades',
  'Jeremy Schunk',
  'Andrea Terry',
  'Matt Thomas',
  'Derek Wolfe',
  'Nicholas Alger',
  'Lenora Allen',
  'Scott Ament',
  'Dennis Andres',
  'Lynn Andrews',
  'Ricky Arnold',
  'Bill Bartok',
  'Doug Bazata',
  'Christian Berger',
  'Walter Blichasz',
  'John Boberg',
  'Christine Bounds',
  'Bryan Bourgeois',
  'Will Brigmon',
  'Wes Brown',
  'Derek Brozovich',
  'Jonathan Bruntz',
  'Jimmy Burton',
  'Sonya Bystrom',
  'James Cain',
  'Cole Calhoun',
  'Lori Carroll',
  'Grant (Jesse) Caulder',
  'Chad Caulder',
  'Matt Chehardy',
  'Adam Chidester',
  'Mike Chwalibog',
  'Mark Cobane',
  'Paul Coder',
  'Christopher Cole',
  'Chauncey Colvin',
  'James Cory',
  'Cydnea Covington',
  'John Barry Cox',
  'Greg Creech',
  'Wesley Cross',
  'Marlo Cucchiaro',
  'Dominic Cunetto',
  'Ian Cousino',
  'Mark Daily',
  'Bill Davis',
  'Brent Davis',
  'Derrick Davis',
  'Don Detering',
  'Steven Dobson',
  'Josh Donahue',
  'Jennifer DuPrey',
  'Curtis Edwards',
  'Clarissa Estrada',
  'Kevin Eubanks',
  'Garrett Flagg',
  'Mitch Fleming',
  'Edward Fosmire',
  'Tom Gardner',
  'Brandon Gautreau',
  'Mike Gilbert',
  'Gustavo Gonzalez',
  'Edward Grey',
  'Trent Grubbs',
  'Darrell Hall',
  'Mark Hallion',
  'Lawrence Hammonds',
  'Patrick Hart',
  'Ryan Hearn',
  'Jason Hendren',
  'Narciso Javier Herrera',
  'Frank Hilberath',
  'Jerimiah Hilger',
  'Nick Hodges',
  'William Holleran',
  'Charles Howse',
  'Jack Huerta',
  'Kevin Hutchinson',
  'Michele Isom',
  'Thomas Jardine',
  'Eric Jefferson',
  'Ian Jeffrey',
  'Angela Johnson',
  'Mike Johnston',
  'Floyd Jones',
  'Ted Kays',
  'Nancy Kelsch',
  'Mike King',
  'Lisa Kita',
  'Matt Kohrs',
  'Michael Korchynski',
  'Steven Kornhaus',
  'Matt Kortsep',
  'Dan Kozlowski',
  'Abe Kruse',
  'Ben Ladue',
  'Lucky Langford',
  'Thomas Libby',
  'Rick  Lyon',
  'Michael Madrigal',
  'Brad Magaha',
  'Corey Magee',
  'Keith Martoccio',
  'Daryl Masoner',
  'Michael McGarvey',
  'Scott McHugh',
  'Mike McMahan',
  'Bonnie McMahon',
  'Kevin McNabb',
  'Jason McNeeley',
  'Rudolph Medved',
  'Jacqueline Migues Pearson',
  'Christy Milam',
  'John Cole Montgomery',
  'Charles Moore',
  'Robert Moore',
  'Juan Morales',
  'Robert Moran',
  'Jeff Morehouse',
  'John Morin',
  'Fred Motley',
  'Nicole Muller',
  'Bruce Murphy',
  'John Myers',
  'Frank Nelson',
  'Jim Newsome',
  'Logan Nicholson',
  'Clay Norden',
  'Allan Orr',
  'Phyllis Paul',
  'Reynaldo Pepin',
  'Jose Perez',
  'Brandon Pineiro',
  'Jason Plank',
  'Jose Luis Ramos',
  'Tina Redmond',
  'Shannon Reynolds',
  'George Richardson',
  'Michael Rickert',
  'Eduardo Rodriguez',
  'Jacob Rudolphi',
  'Blake Runyon',
  'Terry Sawyer',
  'Joe Simon',
  'Anthony Singleton',
  'Jamie Smerdel',
  'Andy Smith',
  'Laurence Spayd',
  'Khimberli Strozier',
  'Adam Swanson',
  'Parrish Swift',
  'Walter Stan Taylor',
  'Cheri Teer',
  'Faith Thomas',
  'Jamison Todd',
  'Chad Toeniskoetter',
  'Bobby Trevino',
  'Damen Troutman',
  'Perry Turner',
  'John Viereck',
  'Ty Villanova',
  'Kerry Vowell',
  'Marcus Walters',
  'Benjamin Wang',
  'William Washington',
  'Jez Watkins',
  'Linda Watkins',
  'Mike Weathersby',
  'Shannon Weismantle',
  'Curtis West',
  'Rashunda Whitfield',
  'Kelly Whittington',
  'Darrin Wilcox',
  'Gary Wilkins',
  'Michael Willhite',
  'Adam Wolfe',
  'Haley Woods',
  'Dustin Wright',
  'Bill Wyscarver',
  'Kim Armstrong',
  'Kellie Andrews',
  'Kellie Barron',
  'Jessica Becker',
  'Laura Bloemker',
  'Lori Bodine',
  'Betsy Boggs',
  'Rachel Bolender',
  'Keirstan Bowling',
  'Beck Braylon',
  'Jordan Buck',
  'Rachel Butt',
  'Lauren Calton',
  'Stephanie DeBoard',
  'Tiffany Doersam',
  'Matt Dopp',
  'Holly Dumford',
  'Kristie Dunn',
  'Kevin Edwards',
  'Courtney Fangman',
  'Michael Farmer',
  'Payne Fisher',
  'Trevor Gould',
  'Steve Gregoire',
  'Bill Heeb',
  'Tony Jeter',
  'Deron Johnson',
  'Eric Johnston',
  'Rebecca Kellerman',
  'Taylor Kelley',
  'Bill Kelly',
  'Brittany Kinner',
  'Amberlin Kuttler',
  'Perry Mayerle',
  'Kimberly Naylor',
  'Michael Norris',
  'Samantha Porter',
  'Kathleen Poynter',
  'Frieda Reese',
  'Jenney Rudd',
  'Jamie Scaggs',
  'Laura Scott',
  'Aaron Simpson',
  'Danielle Tarter',
  'Katie Taylor',
  'Michael Vance',
  'Kelsie Wagner',
  'Heather West',
  'Amy Wilson',
  'Shelby Wingate',
  'Cathy Wright',
  'Taylor Wulker',
  'Kyle Yockey',
  'Krystal DeVito',
  'Vicky Fletcher',
  'David Gifford',
  'Kurt Groetsch',
  'Eric Hedger',
  'Stephanie Hembree',
  'Keith Hensley',
  'Kaplan, Laura',
  'Rob Keller',
  'Elizabeth Lindgren-Royce',
  'Keith Roberts',
  'Paul Spanja',
  'CCA',
  'Bek Kraylon',
  'Dylan Poynter',
  'Dannie Tarter',
  'CCA Brittany Snoddy',
  'CCA Hillary Rape',
  'CCA Mary Efird',
  'Mac Fosmire',
  'Justin Pappenheimer',
  'Heather Bise',
  'Mark Lamb',
  'Rebecca Sunday',
  'Kelly Sheldon',
  'Aaron Simpson',
  'Jessica Roettgers',
  'Chace Courtney'
];

const supervisorNames = [
  'Nancy Kelsch',
  'Adam Wolfe',
  'Kevin Hutchinson',
  'Garrett Flagg',
  'Jason Hendren',
  'Dennis Andres',
  'Kevin McNabb',
  'Randy Beal',
  'Misty Brown-Fisher',
  'Ted Parrott',
  'Dale Medley',
  'Angie Johnson',
  'Jim Dronen',
  'Darrin Wilcox',
  'Kevin Lance',
  'Mike Piercey',
  'Paul Lalande',
  'Tony Jeter',
  'Kevin Edwards',
  'Jessica Becker',
];

const examinerNames = [
  'Sam Porter',
  'Rachel Butt',
  'Michael Vance',
  'Tiffany Doersam',
  'Eric Johnston',
  'Holly Dumford',
  'Laura Bloemker',
  'Preston Price',
  'Joseph Simon'
];

const cssLeadNames = [
  'Christina Hedges',
  'Sherri Walters',
  'Kelly Sheldon',
  'Jennifer Bessenbach',
  'Amber Jacobs',
  'Pam Sparks',
];

const sortByLastName = (a, b) => {
  const aNameParts = a.split(' ');
  const bNameParts = b.split(' ');
  const aLastName = aNameParts[aNameParts.length - 1];
  const bLastName = bNameParts[bNameParts.length - 1];
  if (aLastName > bLastName) {
    return 1;
  }
  if (bLastName > aLastName) {
    return -1;
  }
  return 0;
};

const Contacts = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <FormControl className={classes.formControl}>
        <CustAutofill
          freeSolo
          autoHighlight
          name='claims.adjuster'
          options={adjusterNames.sort(sortByLastName)}
          label='Adjuster'
        />
      </FormControl>
      <FormControl className={classes.formControl}>
      <CustAutofill
          freeSolo
          autoHighlight
          name='claims.examiner'
          options={examinerNames.sort(sortByLastName)}
          label='Examiner'
        />
      </FormControl>
      <FormControl className={classes.formControl}>
      <CustAutofill
          freeSolo
          autoHighlight
          name='claims.supervisor'
          options={supervisorNames.sort(sortByLastName)}
          label='Supervisor'
        />
      </FormControl>
      <FormControl className={classes.formControl}>
      <CustAutofill
          freeSolo
          autoHighlight
          name='claims.cssLead'
          options={cssLeadNames.sort(sortByLastName)}
          label='CSS Lead'
        />
      </FormControl>
    </Fragment>
  );
};

export default Contacts;

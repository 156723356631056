import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
}));

const CommentsField = ({ label, ...props }) => {
  const classes = useStyles();
  const [field] = useField(props);
  return (
    <TextField
      {...field}
      multiline
      rows={25}
      className={classes.textField}
      variant="outlined"
      label={label}
    />
  );
};

export default CommentsField;

import React, { Fragment } from "react";

import CustRadio from "../fields/CustRadio";

const OptIn = () => {
  return (
    <Fragment>
      <CustRadio name="cs.optIn" type="radio" value="Yes" label="Yes" />
      <CustRadio name="cs.optIn" type="radio" value="No" label="No" />
    </Fragment>
  );
};

export default OptIn;

import React from "react";
import { useField } from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const CustSelect = ({ options, labelid, ...props }) => {
  const [field] = useField(props);
  return (
    <Select {...field} labelId={labelid}>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustSelect;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import Warning from "./Warning";

const TypeWarning = (props) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const { showTypeWarning, setShowTypeWarning } = props;

  const handleClose = (selected) => {
    if (selected) {
      if (values.claims.callType === "Other") {
        setFieldValue("claims.reason", "");
        setFieldTouched("claims.reason", false);
      } else {
        setFieldValue("claims.claimNumber", "");
        setFieldTouched("claims.claimNumber", false);
        setFieldValue("claims.callerType", "");
        setFieldValue("claims.division", "");
        setFieldValue("claims.adjuster", "");
        setFieldTouched("claims.adjuster", false);
        setFieldValue("claims.examiner", "");
        setFieldTouched("claims.examiner", false);
        setFieldValue("claims.supervisor", "");
        setFieldTouched("claims.supervisor", false);
        setFieldValue("claims.reason", "");
        setFieldTouched("claims.reason", false);
        setFieldValue("claims.attempts", "");
        setFieldTouched("claims.attempts", false);
        setFieldValue("claims.transferred.adjuster", false);
        setFieldValue("claims.transferred.examiner", false);
        setFieldValue("claims.transferred.supervisor", false);
        setFieldValue("claims.outcome.adjuster", "");
        setFieldTouched("claims.outcome.adjuster", false);
        setFieldValue("claims.outcome.examiner", "");
        setFieldTouched("claims.outcome.examiner", false);
        setFieldValue("claims.outcome.supervisor", "");
        setFieldTouched("claims.outcome.supervisor", false);
      }
      setFieldValue("claims.callType", showTypeWarning.option);
    }
    setShowTypeWarning({ show: false, option: "" });
  };

  return (
    <Fragment>
      <Warning
        showWarning={showTypeWarning}
        handleClose={handleClose}
        title="Change call type selection?"
        message="This will erase any data entered for the current call type."
      />
    </Fragment>
  );
};

TypeWarning.propTypes = {
  showTypeWarning: PropTypes.object.isRequired,
  setShowTypeWarning: PropTypes.func.isRequired,
};

export default TypeWarning;

import React from "react";
import { useField } from "formik";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CustRadio = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <FormControlLabel
      {...field}
      control={<Radio color="primary" />}
      label={label}
    />
  );
};

export default CustRadio;

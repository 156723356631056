import React from "react";
import { useField, useFormikContext } from "formik";
import { makeStyles } from "@material-ui/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  groupName: {
    marginLeft: "1em",
    marginBottom: "0.5em",
    marginTop: "0.5em",
  },
}));

const reasonsGen = [
  "Available Coverages",
  "Eligibility",
  "UW Concerns",
  "Quoting Assistance",
  "Supervisor Calls",
  "Proof of Coverage",
  "AMIG Contact Info",
  "Renewal/Conversion",
  "Activities",
  "Policy Changes",
  "Pet Health",
  "Conversion Dual Maint.",
];

const reasonsProd = [
  "Credentials",
  "AOR's",
  "Company Codes",
  "Unauthorized Agent",
];

const reasonsBill = [
  "Refund",
  "Fee Waiver",
  "EZPay Maintenance",
  "EZPay New Setup",
  "General Payments",
  "Payment Errors",
  "Reading Billing Screens",
  "LH Billing",
  "Commercial Billing",
];

const reasonsCan = [
  "Can't Pay (COVID)",
  "Switched Carriers",
  "Property No Longer Owned",
  "No Loss/Reinstatement",
  "Policy Rewrite",
  "Other (see comments)",
];

const reasonsSys = [
  "MSB Issues",
  "Claims/Clue Report Info",
  "Quoting/System Errors",
  "Online Services Support",
  "Setting Up Account",
];

const reasonsOth = [
  "Sales Rep",
  "Marketing",
  "Claims",
  "Accounting",
  "Agent Experience",
  "Underwriting",
  "General Agent/Level 1",
  "Wrong Number/Company",
  "AmSuite",
  "Commercial",
  "Partner Assistance",
];

const CSReasonsField = ({ labelid, ...props }) => {
  const { values } = useFormikContext();

  const classes = useStyles();

  const [field] = useField(props);

  return (
    <Select
      {...field}
      labelId={labelid}
      multiple
      renderValue={(selected) => selected.join(", ")}
    >
      <Typography className={classes.groupName} variant="subtitle2">
        General
      </Typography>
      {reasonsGen.map((reas) => (
        <MenuItem key={reas} value={reas}>
          <Checkbox
            color="primary"
            checked={values.cs.reason.indexOf(reas) > -1}
          />
          <ListItemText primary={reas} />
        </MenuItem>
      ))}
      <Typography className={classes.groupName} variant="subtitle2">
        Producer Management
      </Typography>
      {reasonsProd.map((reas) => (
        <MenuItem key={reas} value={reas}>
          <Checkbox
            color="primary"
            checked={values.cs.reason.indexOf(reas) > -1}
          />
          <ListItemText primary={reas} />
        </MenuItem>
      ))}
      <Typography className={classes.groupName} variant="subtitle2">
        Billing
      </Typography>
      {reasonsBill.map((reas) => (
        <MenuItem key={reas} value={reas}>
          <Checkbox
            color="primary"
            checked={values.cs.reason.indexOf(reas) > -1}
          />
          <ListItemText primary={reas} />
        </MenuItem>
      ))}
      <Typography className={classes.groupName} variant="subtitle2">
        Cancel/Rewrite
      </Typography>
      {reasonsCan.map((reas) => (
        <MenuItem key={reas} value={reas}>
          <Checkbox
            color="primary"
            checked={values.cs.reason.indexOf(reas) > -1}
          />
          <ListItemText primary={reas} />
        </MenuItem>
      ))}
      <Typography className={classes.groupName} variant="subtitle2">
        System Issues
      </Typography>
      {reasonsSys.map((reas) => (
        <MenuItem key={reas} value={reas}>
          <Checkbox
            color="primary"
            checked={values.cs.reason.indexOf(reas) > -1}
          />
          <ListItemText primary={reas} />
        </MenuItem>
      ))}
      <Typography className={classes.groupName} variant="subtitle2">
        Calls for Others
      </Typography>
      {reasonsOth.map((reas) => (
        <MenuItem key={reas} value={reas}>
          <Checkbox
            color="primary"
            checked={values.cs.reason.indexOf(reas) > -1}
          />
          <ListItemText primary={reas} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default CSReasonsField;

import { createMuiTheme } from "@material-ui/core/styles";

const AmBlue = "#00A2D7";
const AmDarkBlue = "#198BB1";
const AmGreen = "#3D9B29";
const AmDarkGreen = "#3E9B2A";
const AmPurple = "#35246F";
const AmLightPurple = "#7471B1";
const AmGray = "#F5F5F5";

export default createMuiTheme({
  palette: {
    common: {
      blue: `${AmBlue}`,
      darkblue: `${AmDarkBlue}`,
      green: `${AmGreen}`,
      darkgreen: `${AmDarkGreen}`,
      purple: `${AmPurple}`,
      lightpurple: `${AmLightPurple}`,
      gray: `${AmGray}`,
    },
    primary: {
      main: `${AmBlue}`,
    },
    secondary: {
      main: `${AmGreen}`,
    },
  },
});

import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  oneField: {
    width: "60%",
  },
}));

const RegField = ({ label, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <TextField
      {...field}
      helperText={errorText}
      error={!!errorText}
      className={classes.oneField}
      label={label}
    />
  );
};

export default RegField;

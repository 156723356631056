import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';
import LoginButtonComponent from '../ui/LoginButtonComponent';
import LogoutButtonComponent from '../ui/LogoutButtonComponent';

import logo from '../../assets/AM-logo.png';

// import UserContext from '../../context/user/userContext';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: '1.1em',
  },
  logo: {
    height: '5em',
  },
  logoutButton: {
    color: 'white',
  },
  navText: {
    marginLeft: '0.8em',
    fontWeight: 300,
  },
  profile: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
}));

const Header = () => {
  const { instance, accounts } = useMsal();
  const [name, setName] = useState(null);
  const [username, setUsername] = useState(null);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (accounts.length > 0) {
      const name = accounts[0].name.split(' - ')[0].split(' ');
      setName(`${name[1]} ${name[0]}`);
      setUsername(accounts[0].username);
    }
  }, [accounts]);

  const classes = useStyles();

  return (
    <Fragment>
      <AppBar position='absolute' elevation={0} className={classes.appBar}>
        <Toolbar>
          <img src={logo} alt='company logo' className={classes.logo} />
          <Typography variant='h6' className={classes.navText}>
            Call Tracker
          </Typography>
          <div className={classes.profile}>
            {name && (
              <Typography variant='body2'>
                <span style={{ fontWeight: 'bold' }}>{name}</span> | {username}
              </Typography>
            )}
            {isAuthenticated ? (
              <LogoutButtonComponent />
            ) : (
              <LoginButtonComponent loginType='redirect' />
            )}
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarMargin}></div>
    </Fragment>
  );
};

export default Header;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Department = (props) => {
  const { dirty } = useFormikContext();

  const { department, setDepartment, setShowDepWarning, setActiveStep } = props;

  const handleChange = (e) => {
    e.preventDefault();
    if (dirty) {
      setShowDepWarning({ show: true, option: e.target.value });
    } else {
      setDepartment(e.target.value);
      setActiveStep(1);
    }
  };

  return (
    <Fragment>
      <RadioGroup
        name="department"
        value={department}
        onChange={handleChange}
        row
      >
        <FormControlLabel
          value="Customer Service"
          control={<Radio color="primary" />}
          label="Customer Service"
        />
        <FormControlLabel
          value="Claims"
          control={<Radio color="primary" />}
          label="Claims"
        />
      </RadioGroup>
    </Fragment>
  );
};

Department.propTypes = {
  department: PropTypes.string.isRequired,
  setDepartment: PropTypes.func.isRequired,
  setShowDepWarning: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

export default Department;
